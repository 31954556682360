<template>
  <div>
    <v-col cols="12" md="12">
      <Creditos />

      <v-tabs v-model="currentTab">
        <v-tab :value="0">Mensaje</v-tab>
        <v-tab v-if="has_google_account" :value="1">Evento</v-tab>
      </v-tabs>

      <app-card class="mb-5">
        <v-tabs-items v-model="currentTab">
          <v-tab-item :value="0">
            <app-card-body >
              <div class="d-flex justify-space-between align-baseline">
                <app-card-header class="pl-0 pt-0">Enviar Mensaje</app-card-header>
                <v-btn
                  color="secondary"
                  outlined
                  class="absolute"
                  @click="$refs.messages_templates_modal.openTemplatesModal(getSelectedCompany)"
                >
                  <span>Plantillas de mensajes</span>
                </v-btn>
              </div>
              <v-row>
                <v-col cols="12" md="6">
                  <message-form ref="message_form"
                    @send-message-order="sendMessage"
                    @is-valid-to-send="setValidationResult"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <drag-and-drop ref="drag_and_drop"
                    @files-to-send="bindFilesToSend"
                  />
                </v-col>
                <v-col cols="12" align="right" v-if="thereIsAnError" style="color: #ff4c51" class="font-weight-semibold">
                  Ocurrió un error. Por favor, vuelva a intentar en unos minutos.
                </v-col>

                <v-col cols="12" align="right" v-if="errorMsg" style="color: #ff4c51" class="font-weight-semibold">
                  {{ errorMsg }}
                </v-col>

                <!-- <div>{{ isValidToSend }}</div>
                <div>credits = 0 {{ getCredits === 0 }}</div>
                <div> user type {{ getUserRole }}</div> -->
                <v-col cols="12 d-flex" style="justify-content: flex-end;">
                  <v-btn
                    color="primary"
                    text
                    outlined
                    @click="toggleModal()"
                    :disabled="!isValidToSend || (getUserRole !== 0 && getCredits === 0) || errorMsg || disableSendBtn"
                  >
                    Programar
                  </v-btn>
                  <ScheduleMsgModal ref="schedule_msg" class="mr-2"
                    @schedule-message-order="scheduleMessage"
                    @click="$refs.message_form.sendMessage()"
                  />
                  <v-btn
                    color="primary"
                    @click="$refs.message_form.sendMessage()"
                    :disabled="!isValidToSend || (getUserRole !== 0 && getCredits === 0) || errorMsg || disableSendBtn"
                  >
                    {{disableSendBtn ? 'Enviando' : 'Enviar'}}
                  </v-btn>
                </v-col>
              </v-row>

            </app-card-body>
          </v-tab-item>

          <v-tab-item :value="1">
            <app-card-body >
              <div class="d-flex justify-space-between align-baseline">
                <app-card-header class="pl-0 pt-0">Crear Evento</app-card-header>
              </div>
              <v-row>
                <v-col cols="12" md="12">
                  <event-form ref="event_form"
                  @send-event-order="sendEvent"
                  />
                </v-col>

                <v-col cols="12 d-flex" style="justify-content: flex-end;">
                  <v-btn
                    color="primary"
                    @click="$refs.event_form.sendEvent()"
                    :disabled="disableSendBtn"
                  >
                    {{disableSendBtn ? 'Creando' : 'Crear'}}
                  </v-btn>
                </v-col>
              </v-row>
            </app-card-body>
          </v-tab-item>
        </v-tabs-items>
      </app-card>

      <alert-pop-up ref="alert_pop_up" />

      <!-- <div v-if="false" style="margin-bottom: 1rem">
        <v-btn @click="test()"
          elevation="2"
        >Test</v-btn>
      </div> -->

      <MessagesList v-if="currentTab === 0" ref="messages_list_component" />

      <EventsList v-if="currentTab === 1" ref="events_list_component" />

      <TemplatesList ref="messages_templates_modal" @insert-template="sendTemplateToForm"/>

    </v-col>
  </div>
</template>

<script>

import MessageForm from '@/components/Messages/MessageForm';
import DragAndDrop from '@/components/Messages/DragAndDrop';
import Creditos from '@/components/Messages/Creditos';
import MessagesList from '@/components/Messages/MessagesList';
import AppCard from '@/@core/components/app-card/AppCard'
import AppCardBody  from '@/@core/components/app-card/AppCardBody'
import AppCardHeader  from '@/@core/components/app-card/AppCardHeader'
import ScheduleMsgModal from '@/components/Messages/ScheduleMsgModal.vue';
import AlertPopUp  from '@/components/AlertPopUp.vue';
import TemplatesList from '@/components/Templates/TemplatesList'
import EventForm from '@/components/Messages/EventForm';
import EventsList from '@/components/Messages/EventsList';


export default {
  data: () => {
    return {
      filesToSend: [],
      thereIsAnError: false,
      isValidToSend: false,
      errorMsg: null,
      disableSendBtn: false,
      currentTab: null,
      has_google_account: false
    }
  },
  mounted() {
    if (this.getSelectedCompany) {
      this.checkIfCompanyHasChannel()
      this.checkIfCompanyHasGoogle()
    }
  },
  methods: {
    async checkIfCompanyHasChannel() {
      try {
        const response = await this.$api.checkIfCompanyHasChannel(this.getSelectedCompany)
        // console.log(response.status)
        this.errorMsg = null
      } catch (e) {
        console.log(e);
        // console.log(e.response.status);
        if (e?.response?.status === 403) {
          this.errorMsg = e?.response?.data?.message
        }
      }
    },
    async checkIfCompanyHasGoogle() {
      this.has_google_account = false
      try {
        const response = await this.$api.checkIfCompanyHasGoogle(this.getSelectedCompany)
        // console.log(response.status)
        this.has_google_account = response.data.has_google_account
        this.errorMsg = null
      } catch (e) {
        console.log(e);
        // console.log(e.response.status);
        if (e?.response?.status === 403) {
          this.errorMsg = e?.response?.data?.message
        }
      }
    },
    toggleModal () {
      this.$refs.schedule_msg.scheduleModal = !this.$refs.schedule_msg.scheduleModal
      this.$refs.schedule_msg.setMinDate()
    },

    sendTemplateToForm(template) {
      this.$refs.message_form.insertToMessage(template)
    },

    setValidationResult(is_valid) {
      this.isValidToSend = is_valid
    },
    // async test() {
    //   console.log('test response')
    //   this.$api.getChannels(this.getSelectedCompany)
    //     .then(response => console.log(response))

    //   // console.log(response);
    // },

    async getCreditsLocal() {
      try {
        const credits = await this.$api.getCurrentUserCredits(this.getSelectedCompany)
        if (credits) {
          this.$store.dispatch('session/setCredits', credits)
        } else {
          this.$store.dispatch('session/setCredits', 0)
        }
      } catch (e) {
        console.log(e)
      }
    },

    bindFilesToSend(files) {
      this.filesToSend = files
    },

    resetComponent() {
      this.$refs.message_form.reset()
      this.$refs.drag_and_drop.reset()
      this.$refs.schedule_msg.reset()

      if (this.getUserRole !== 0) {
        this.getCreditsLocal()
      }

      this.$refs.messages_list_component.getMessages(1, 10)
    },
    resetEvent() {
      this.$refs.event_form.reset()
      this.$refs.events_list_component.getEventsList()
      if (this.getUserRole !== 0) {
        this.getCreditsLocal()
      }
    },
    async scheduleMessage(scheduleForm){
      //getting messageForm
      const formMessage = this.$refs.message_form.generateMsgForm();
      //merging messageForm with scheduleForm
      const formData = new FormData();
      Object.keys(scheduleForm).forEach(key => {
        formData.append(key, scheduleForm[key]);
      });
      Object.keys(formMessage).forEach(key => {
        formData.append(key, formMessage[key]);
      });
      //adding files
      if (this.filesToSend.length) {
        for (let i = 0; i < this.filesToSend.length; i++) {
          formData.append('files', this.filesToSend[i])
        }
      }

      try {
        this.disableSendBtn = true;
        const response = await this.$api.scheduleMessage(formData);
        console.log(response);
        if (response.status === 200) {
          this.disableSendBtn = false;
        } else {
          this.disableSendBtn = false;
        }
      } catch (error) {
        console.log(error);
        this.disableSendBtn = false;
      }
      this.resetComponent();
    },
    async sendMessage(formMessage) {

      const formData = new FormData()

      for (const key in formMessage) {
        formData.set(key, formMessage[key])
      }

      if (this.filesToSend.length) {
        for (let i = 0; i < this.filesToSend.length; i++) {
          formData.append('files', this.filesToSend[i])
        }
      }

      try {

        this.disableSendBtn = true
        const response = await this.$api.sendMessage(formData)

        if (response.status === 200) {
          this.$refs.alert_pop_up.showPopUp({ message: 'Mensaje enviado correctamente', type: 'success' })
          this.resetComponent()
          this.disableSendBtn = false
        }

      } catch (error) {
        console.log(error);
        if(error?.response?.status === 403) {
          this.$refs.alert_pop_up.showPopUp({ message: error?.response?.data?.message, type: 'error' })
        } else {
          this.thereIsAnError = true
          this.$refs.alert_pop_up.showPopUp({ message: 'Ocurrió un error', type: 'error' })
          this.disableSendBtn = false
        }
      } console.log(formData);/* finally {
        this.resetComponent()
      } */

    },

    async sendEvent(eventForm) {
      this.disableSendBtn = true
      try {
       // console.log(eventForm)
        const response = await this.$api.createEvent(eventForm)

        this.$refs.alert_pop_up.showPopUp({ message: 'Evento creado correctamente', type: 'success' })
        this.resetEvent()
      } catch(e) {
        console.log(e)
        if (e?.response?.status === 400) {
          console.log(e?.response?.data.email)
          if(e?.response?.data?.email) {
            this.$refs.alert_pop_up.showPopUp({ message: e?.response?.data.email[0], type: 'error' })
          }
          if (Array.isArray(e?.response?.data)) {
            this.$refs.alert_pop_up.showPopUp({ message: e?.response?.data[0], type: 'error' })
          }  else if (typeof e?.response?.data === 'string') {
            this.$refs.alert_pop_up.showPopUp({ message: e?.response?.data, type: 'error' })
          }
        } else {
          this.$refs.alert_pop_up.showPopUp({ message: 'Ocurrió un error', type: 'error' })
          this.disableSendBtn = false
        }
      } finally {
        this.disableSendBtn = false
      }
    }
  },
  computed: {
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    getCredits() { return this.$store.getters['session/getCredits']() },
  },
  watch: {
    getSelectedCompany(newValue) {
      if (newValue) {
        this.checkIfCompanyHasChannel()
        this.checkIfCompanyHasGoogle()
      }
    }
  },
  components: {
    MessageForm,
    DragAndDrop,
    AppCard,
    AppCardBody,
    AppCardHeader,
    MessagesList,
    Creditos,
    AlertPopUp,
    ScheduleMsgModal,
    TemplatesList,
    EventForm,
    EventsList
  },
}

</script>
