<template>
  <v-form ref="eventForm" @submit.prevent="sendEvent">
    <v-row>
      <v-col cols="6" md="12">
        <v-text-field
          ref="name_ref"
          v-model.trim="event_name"
          :rules="[v => !!v || 'Campo requerido', v => (!!v && v.length < 201 )|| 'Solo se permiten hasta 200 carácteres']"
          outlined
          dense
          autocomplete="off"
          :hint="`${event_name ? event_name.length : 0}/200`"
          persistent-hint
          label="Nombre"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <v-radio-group
          ref="radio_group"
          class="mt-0"
          v-model="radio_option"
          :rules="[(v) => !!v || 'Campo requerido']"
          @change="resetRadioOptions()"
          row
        >
          <v-radio label="Ingresar Email" value="email_option"></v-radio>
          <v-radio label="Seleccionar Contacto" value="contact_option"></v-radio>
        </v-radio-group>

        <v-col
          cols="12"
          md="12"
          class="pt-0 pb-0"
          v-if="radio_option === 'email_option'"
        >
          <v-text-field
            v-model="event_email"
            :rules="[(v) => !!v || 'Campo requerido']"
            outlined
            dense
            autocomplete="off"
            hide-details
            label="Email"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="12"
          class="pt-0 pb-0"
          v-if="radio_option === 'contact_option'"
        >
          <v-combobox
            v-model="event_selected_contact"
            :items="contactsSelectData"
            :search-input.sync="searchContactQuery"
            :rules="[(v) => !!v || 'Campo requerido', !contactNoEmailError || 'No tiene email vinculado']"
            label="Seleccione uno o varios contactos"
            outlined
            :hint="event_contact ? `Email vinculado: ${event_contact}` : ''"
            dense
            item-text="name"
            :item-value="(item) => item"
          >
            <template #item="data">
              <v-list-item-content @click="searchContactQuery = ''">
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  No se encontraron <strong>contactos</strong>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-combobox>
          <!-- <span
            class="success--text success-msg"
            v-if="event_contact"
            >El email es: {{ event_contact }}</span
          > -->
          </v-col>
      </v-col>

      <v-col cols="4" md="4">
        <v-text-field
          ref="date_ref"
          label="Fecha del evento"
          type="date"
          v-model="event_date"
          :rules="[(v) => !!v || 'Campo requerido']"
          outlined
          dense
          required
        ></v-text-field>
      </v-col>

      <v-col cols="4" md="4">
        <v-menu
          ref="timepicker_ref"
          v-model="timepicker_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="event_time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              ref="time_ref"
              v-model="event_time"
              label="Hora del evento"
              :prepend-inner-icon="icons.mdiClockTimeFourOutline"
              :rules="[(v) => !!v || 'Campo requerido']"
              outlined
              dense
              required
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="timepicker_menu"
            v-model="event_time"
            full-width
            format="24hr"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              outlined
              color="primary"
              @click="$refs.timepicker_ref.save(event_time)"
            >
              OK
            </v-btn>
          </v-time-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="12" >
        <v-textarea
          ref="description_ref"
          :prepend-inner-icon="icons.mdiMessageBulleted"
          v-model.trim="event_description"
          counter="500"
          maxlength="500"
          placeholder="Descripción"
          outlined
          dense
          hint=""
          label="Descripción del evento"
        ></v-textarea>
      </v-col>

      <v-col cols="6" md="6" >
        <v-switch
          v-model="event_reminder"
          :true-value="true"
          :false-value="false"
          hide-details
          label="Recordar antes del evento"
        ></v-switch>
      </v-col>

      <v-col cols="6" md="6" >
        <v-switch
          v-model="event_notification"
          :true-value="true"
          :false-value="false"
          hide-details
          label="Enviar notificación por email del evento"
        ></v-switch>
      </v-col>

      <v-col v-if="event_reminder" cols="4" md="4">
        <v-text-field
          ref="minutes_ref"
          v-model="event_minutes"
          outlined
          :rules="[v => !!v || 'Campo requerido']"
          type="number"
          dense
          autocomplete="off"
          hide-details
          label="Tiempo antes a recordar el evento"
        ></v-text-field>
        <span class="ml-3">En minutos</span>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
const debounce = require('debounce');
import {
  mdiClockTimeFourOutline,
  mdiMessageBulleted
} from '@mdi/js'

export default {
  data:() => {
    return {
      radio_option: null,
      event_date: null,
      event_email: null,
      event_time: null,
      event_description: null,
      event_name: null,
      event_notification: false,
      event_selected_contact: null,
      event_contact: null,
      event_reminder: false,
      event_minutes: null,
      searchContactQuery: '',
      timepicker_menu: false,
      contactNoEmailError: false,
      contactsSelectData: []
    }
  },
  methods: {
    resetRadioOptions() {
      console.log(this.radio_option)
      this.setContactsData();
      this.event_email = null;
      this.contactNoEmailError = false;
      this.event_selected_contact = null;
      this.event_contact = null;
    },

    reset() {
      this.$refs.date_ref.reset()
      this.$refs.time_ref.reset()
      this.$refs.description_ref.reset()
      if(this.event_reminder) {
        this.$refs.minutes_ref.reset()
      }
      this.event_notification = false
      this.event_reminder = false
      this.resetRadioOptions()
      this.$refs.radio_group.reset()
    },

    async sendEvent() {
      if (this.$refs.eventForm.validate()) {
        const form = this.generateEventForm()

        this.$emit('send-event-order', form)
      }
      // console.log('Evento')
    },

    generateEventForm() {
      let form = {
        company: this.getSelectedCompany,
        datetime: `${this.event_date}T${this.event_time}:00-03:00`,
        description: this.event_description,
        notify: this.event_notification,
        remind: this.event_reminder,
        name: this.event_name
      }

      if(this.event_reminder) {
        form.remind_minutes = this.event_minutes
      }

      if(this.radio_option === 'email_option') {
        form.email = this.event_email
      }

      if(this.radio_option === 'contact_option') {
        form.contact = this.event_selected_contact.id
      }

      return form
    },

    async checkIfEmail() {
      this.contactNoEmailError = false
      this.event_contact = null
      if(this.event_selected_contact) {
        try {
          const response = await this.$api.getContactEmail(this.event_selected_contact.id)
          if(response.data.email) {
            this.event_contact = response.data.email
          } else {
            this.contactNoEmailError = true
          }
          console.log(this.event_contact)
        } catch(e) {
          console.log(e)
        }
      }
    },

    handleContactSearch: debounce(function (e) {
      this.setContactsData();
    }, 300),

    async setContactsData() {
      if (this.getSelectedCompany) {
        const query = {
          company_id: this.getSelectedCompany,
          page: 1,
          page_size: 20,
          search: this.searchContactQuery,
        };

        try {
          const response = await this.$api.getContacts(query);
          this.contactsSelectData = response.results;
          // this.form_blacknumbers = []
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  watch: {
    searchContactQuery(val) {
      console.log(val)
      this.handleContactSearch();
    },
    getSelectedCompany(val) {
      if(val) this.handleContactSearch()
    },
    event_selected_contact(val) {
      if(val) this.checkIfEmail()
    }
  },
  mounted() {
    this.setContactsData()
  },
  computed: {
    getSelectedCompany() {
      return this.$store.getters["session/getSelectedCompany"]();
    },
  },
  setup() {
    return {
      icons: {
        mdiClockTimeFourOutline,
        mdiMessageBulleted
      }
    }
  }
}
</script>
