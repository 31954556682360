<template>
    <div>
    <!-- list filters -->
    <v-card>
      <v-card-title>
        Mensajes
      </v-card-title>


      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          @keyup="handleSearch"
          placeholder="Buscar por usuario, canal, cliente, mensaje o teléfono"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <v-select
          style="width: 0px"
          class="mb-4"
          :items="[{name: '-', value: -1}, {name: 'Pendiente', value: 0}, {name: 'Enviado', value: 1}, {name: 'Fallido', value: 2}]"
          hide-details="true"
          v-model="stateFilterValue"
          label="Estado"
          item-text="name"
          item-value="value"
          @change="setFilterStatus"
          outlined
          dense
        ></v-select>


        <v-spacer></v-spacer>


        <div v-if="getUserRole !== 2" class="d-flex align-center flex-wrap">
          <v-btn
            color="secondary"
            outlined
            class="mb-4"
            @click="exportMessages()"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Exportar</span>
          </v-btn>
        </div>

        <div v-if="toDownload && getUserRole !== 2" class="d-flex align-center flex-wrap ml-2">
          <v-btn
            color="info"
            outlined
            class="mb-4"
            @click="downloadExport()"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiDownloadOutline }}
            </v-icon>
            <span>Descargar</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="messagesListTable"
        :page="current_page"
        :options.sync="options"
        :loading="loading"
        :server-items-length="totalUserListTable"
        :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
        @update:page="changingMessagesPage($event)"
        @update:items-per-page="changingMessagesItemsPerPage($event)"
      >
      <!-- show-select -->

        <!-- no-data -->
        <template  v-slot:no-data>
          {{
            getSelectedCompany
              ? searchQuery.length? 'No hay mensajes que coincidan con la búsqueda' : 'Aún no hay mensajes'
              : 'No hay empresa seleccionada. Seleccione una para ver los mensajes asociados'
          }}
        </template>

        <!-- ack_status column header -->
        <template v-slot:header.ack_status="{ header }">
          <v-icon
              :color="'secondary'"
              >
                {{icons.mdiWhatsapp}}
          </v-icon>
        </template>

        <!-- Ack-status items-->
        <template #[`item.ack_status`]="{item}">
          <span class=""
          v-if="item.status.label === 'SENDED'"
          >
            <button
            class="mr-3"
            @click="checkStatusAck(item.id)"
            :disabled="item.ack_status === 'not check again' || item.ack_status === 'read' || !item.ack_status.length"
            >
              <!-- <v-icon class="loader"></v-icon> -->
              <v-icon
              :color="'primary'"
              :disabled="item.ack_status === 'not check again' || item.ack_status === 'read' || !item.ack_status.length"
              >
                {{icons.mdiReloadAlert}}
              </v-icon>
            </button>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class=""
                  :color="resolveAckVariant(item.ack_status)"
                  small
                  dark
                  v-bind="attrs"
                  v-on="on"
                  >{{ item.ack_status === 'send' ? icons.mdiCheck : icons.mdiCheckAll}}
                </v-icon>
                </template>
              <span>{{translateAck(item.ack_status)}}</span>
            </v-tooltip>

          </span>
        </template>

        <!-- user -->
        <template #[`item.user`]="{item}">
          <div class="d-flex align-center">

            <div class="d-flex flex-column ms-3">
              {{ item.user }}
            </div>
          </div>
        </template>

        <!-- channel -->
        <template #[`item.channel`]="{item}">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.channel }}</span>
          </div>
        </template>

        <!-- phone -->
        <template #[`item.phone`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.phone }}</span>
        </template>

        <!-- client -->
        <template #[`item.client`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3 text-justify">
              {{ item.client }}
            </div>
          </div>
        </template>

        <!-- message -->
        <template #[`item.message`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3 text-justify">
              <span v-if="item.message.length <= 80">{{ item.message }}</span>
              <span v-else>
                {{ item.message.substring(0, 75) + '...' }}
                <v-btn icon x-small @click="showCompleteMsg(item.message)">
                   <v-icon color="primary">{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </span>
            </div>
          </div>
        </template>

        <!-- company -->
        <template #[`item.company`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.company }}</span>
        </template>

        <template #[`item.date`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.send_time != null ? item.send_time.slice(0,10) : '-' }}</span>
        </template>

        <template #[`item.time`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.send_time != null ? item.send_time.slice(11, 16) : '-'}}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <div class="status-div">
            <v-chip
              small
              :color="resolveUserStatusVariant(item.status.label)"
              :class="[`${resolveUserStatusVariant(item.status.label)}--text`, item.status.label != 'PROGRAM' ? 'btn-pointer' : '']"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
              @click="getProgrammedMessageInfo(item)"
            >
              {{ translateStatus(item.status.label) }}
            </v-chip>
            <v-btn
              icon
              max-height="20px"
              max-width="20px"
              class="send-failed-btn"
              x-small
              color="error"
              @click="sendFailedMessage(item)"
              v-if="item.status.label === 'FAILED'"
              >
                <v-icon
                  class="failed-icon"
                  small
                  dark
                  >{{ icons.mdiSendOutline }}</v-icon>
            </v-btn>
          </div>
        </template>


        <!-- files -->
        <template #[`item.files`]="{item}">

          <v-btn v-if="item.files.length"
            class="view-files"
            elevation="2"
            x-small
            outlined
            @click="openAttachedFiles(item.files)"
            :plain="attached_items.length === 1 && attached_items[0] == 'disabled'"
          >
            Ver archivos
          </v-btn>

          <div v-else>-</div>

        </template>

      </v-data-table>


      <div class="text-center">
        <v-dialog
          v-model="attached_files_dialog"
          width="400"
          scrollable
        >

          <!-- Showing if files are outdated -->
          <v-card v-if="attached_items.length === 1 && attached_items[0] == 'disabled'">
            <v-card-title class="text-h5 grey lighten-2">
              Archivos no disponibles
            </v-card-title>

            <v-card-text style="max-height: 300px">
              Los archivos adjuntos solo permanecen guardados durante 7 días.
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="attached_files_dialog = false">
                CERRAR
              </v-btn>
            </v-card-actions>
          </v-card>

          <!-- Showing if files still downloadable -->
          <v-card v-else>
            <v-card-title class="text-h5 grey lighten-2">
              Archivos envíados
            </v-card-title>

            <v-card-text style="max-height: 300px">

              <v-list-item v-for="item in attached_items" :key="`attached_items_${item.id}`">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-btn icon elevation="2" @click="downloadAttachedFile(item.id)">
                    <v-icon v-text="icons.mdiDownload"></v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="attached_files_dialog = false"
              >
                CERRAR
              </v-btn>
            </v-card-actions>
          </v-card>


        </v-dialog>
      </div>
      
      <!-- Show complete Message Text -->
      <template>
          <v-dialog v-model="msg_modal" max-width="500">
            <v-card>
              <v-card-text>
                {{ message }}
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
      <!--Programmed message modal-->
      <template>
        <v-dialog v-model="programmed_message_modal" width="300">
          <v-card>
            <v-card-title>Fecha de envío:</v-card-title>
            <v-card-subtitle>{{ programmed_message_info? programmed_message_info.send_date_program[0] : '' }}</v-card-subtitle>
            <v-card-title>Horario de envío:</v-card-title>
            <v-card-subtitle>{{ programmed_message_info? programmed_message_info.send_time_program : '' }}</v-card-subtitle>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="closeProgrammedMessagesModal()"
              >
                CERRAR
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <alert-pop-up ref="alert_pop_up" />
    </v-card>
    </div>
</template>

<script>
const debounce = require('debounce');

// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiDownload,
  mdiSendOutline,
  mdiWhatsapp,
  mdiCheck,
  mdiCheckAll,
  mdiReloadAlert,
  mdiDownloadOutline
} from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import AlertPopUp  from '@/components/AlertPopUp.vue';

// sidebar
import { avatarText } from '@core/utils/filter'
import useUsersList from './useUsersList'

export default {
  data: () => {
    return {
      attached_files_dialog: false,
      attached_items: [],
      current_page: 1,
      page_size: 10,
      searchQuery: '',
      stateFilterValue: -1,
      timer: null,
      loadIcon: false,
      message: '',
      msg_modal: false,
      programmed_message_info: null,
      programmed_message_modal: false,
      toDownload: false
    }
  },
  components: {
    AlertPopUp
  },
  methods: {

    handleSearch: debounce(function (e) {
      // console.log(this.searchQuery)
      this.current_page = 1
      this.getMessages()
    }, 400),

    setFilterStatus() {
      this.current_page = 1
      this.getMessages()
    },

    changingMessagesPage(e) {
      this.current_page = e
      this.getMessages()
    },

    changingMessagesItemsPerPage(e) {
      this.page_size = e
      this.getMessages()
    },

    openAttachedFiles(files) {
      this.attached_items = files
      this.attached_files_dialog = true
    },

    async exportMessages() {
      try {
        const response = await this.$api.exportMessages(this.getSelectedCompany)
        this.$refs.alert_pop_up.showPopUp({ message: response.message, type: 'success' })
        this.getMessages()
      } catch(e) {
        console.log(e)
      } finally {
        this.toDownload = false
      }
    },

    downloadExport() {
      this.createLinkClicked(this.toDownload)
    },

    async downloadAttachedFile(file_id) {
      const url = await this.$api.downloadAttachedFile(file_id)
      this.createLinkClicked(url)
    },

    createLinkClicked(url) {
      const a = document.createElement("a");
      a.href = url
      a.target = '_blank'
      // console.log(url)
      a.click();
    },

    resetTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => {
        this.getMessages()
      }, 1000 * 45)
    },

    async getMessages() {
      this.loading = true
      try {
        const query = {
          company_id: this.getSelectedCompany,
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
          status: this.stateFilterValue,
        }
        const response = await this.$api.getMessages(query)
        this.messagesListTable = response.results
        this.totalUserListTable = response.count
        this.toDownload = response.file === 'null' ? false : response.file
        // console.log(this.messagesListTable)

      } catch (error) {
        console.log(error)
      } finally { this.loading = false; this.resetTimer() }
    },
    async checkStatusAck(item) {
      this.loading = true
      // this.loadIcon = true
      try {
        const response = await this.$api.checkStatusAck(item)
        this.messagesListTable.forEach(element => element.id === item ? element.ack_status = response : false)
        // console.log(this.messagesListTable)
      } catch (error) {
        console.log(error)
      } finally { this.loading = false; /* this.loadIcon = false; */}
    },
    translateStatus(value) {
      const translation = {
        PENDING: 'PENDIENTE',
        SENDED: 'ENVIADO',
        FAILED: 'FALLIDO',
        PROGRAM: 'PROGRAMADO ⏱'
      }
      return translation[value] ?? 'error'
    },
    translateAck(value) {
      const translation = {
        send: 'Enviado',
        received: 'Recibido',
        read: 'Leído',
      }
      return translation[value] ?? 'Leído'
    },
    async sendFailedMessage(item) {
      try {
        const form = {
          company_id: this.getSelectedCompany
        }

        /* const response = */ await this.$api.sendFailedMessage(item.id, form)
        // console.log(response)

        this.getMessages()
      } catch (error) {
        console.log(error)
      }
    },
    showCompleteMsg(message) {
      this.message = message
      this.msg_modal = true
    },

    async getProgrammedMessageInfo(item) {
      if(item.status.label == 'PROGRAM') {
        try {
          const response = await this.$api.getProgrammedMessageInfo(item.id, this.getSelectedCompany)
          this.programmed_message_info = response
          this.programmed_message_modal = true
        } catch(e) {
          console.log(e)
        }
      }
    },

    closeProgrammedMessagesModal() {
      this.programmed_message_info = null
      this.programmed_message_modal = false
    }

  },
  mounted() {
    if (this.getSelectedCompany) {
      this.loading = true
      this.getMessages()
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
  },
  watch: {
    getSelectedCompany (newValue) {
      if (newValue) {
        this.current_page = 1
        this.page_size = 10
        this.getMessages()
      }
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'


    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      selectedRows,

    } = useUsersList()

    const totalUserListTable = ref(0)

    const tableColumns = [
      { text: 'STATUS', value: 'ack_status', align: 'center', sortable: false, width: '90px'},
      { text: 'USUARIO', value: 'user', align: 'center', sortable: false },
      { text: 'CANAL', value: 'channel', align: 'center', sortable: false },
      { text: 'TELÉFONO', value: 'phone', align: 'center', sortable: false },
      { text: 'CLIENTE', value: 'client', align: 'center', sortable: false },
      { text: 'MENSAJE', value: 'message', align: 'center', sortable: false },
      // { text: 'EMPRESA', value: 'company', align: 'center', sortable: false },
      { text: 'FECHA', value: 'date', align: 'center', sortable: false },
      { text: 'HORA', value: 'time', align: 'center', sortable: false },
      // { text: 'RESPUESTA', value: 'responde', sortable: false },
      { text: 'ESTADO', value: 'status', align: 'center', sortable: false },
      { text: 'ARCHIVOS', value: 'files', align: 'center', sortable: false },
      // {
      //   text: 'ACTIONS',
      //   value: 'actions',
      //   align: 'center',
      //   sortable: false,
      // },
    ]

    const loading = ref(false)


    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })

    const messagesListTable = ref([])

    const resolveUserStatusVariant = status => {
      if (status === 'FAILED') return 'error'
      if (status === 'SENDED') return 'success'
      if (status === 'PENDING') return 'primary'
      if (status === 'PROGRAM') return 'warning'

      return 'primary'
    }

    const resolveAckVariant = ack_status => {
      if (ack_status === 'send' || ack_status === 'received') return 'secondary'
      if (ack_status === 'read' || ack_status === 'not check again') return 'accent'

      return 'accent'
    }


    // const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Author', value: 'author' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Pending', value: 'pending' },
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]

    return {
      messagesListTable,

      tableColumns,
      totalUserListTable,
      roleOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      selectedRows,
      avatarText,
      resolveUserStatusVariant,
      resolveAckVariant,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiDownload,
        mdiSendOutline,
        mdiWhatsapp,
        mdiCheck,
        mdiCheckAll,
        mdiReloadAlert,
        mdiDownloadOutline
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>

<style scoped>
.v-btn--outlined.view-files {
  border-color: lightgray;
}

.status-div {
  display: flex;
  align-items: center;
}

.send-failed-btn {
  margin-left: 2px;
  background-color: #ff4c5226
}

.failed-icon {
  margin-left: 1.5px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.btn-pointer {
  cursor:default
}
</style>
