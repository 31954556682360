<template>
  <div>
    <v-card>
      <v-card-title>
        Eventos
      </v-card-title>

      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-col cols="2" md="2">
          <v-select
            
            class="mb-4"
            :items="[{name: '-', value: -1}, {name: 'Pendiente', value: 0}, {name: 'Creado', value: 1}, {name: 'Fallido', value: 2}]"
            hide-details="true"
            v-model="stateFilterValue"
            label="Estado"
            item-text="name"
            item-value="value"
            @change="setFilterStatus"
            outlined
            dense
          ></v-select>
        </v-col>

        <v-spacer></v-spacer>
      </v-card-text>

      <v-data-table
      :headers="tableColumns"
			:page="current_page"
      :items="eventListTable"
      :server-items-length="totalEventListTable"
      :loading="loading"
      @update:page="changingEventPage($event)"
      @update:items-per-page="changingEventItemsPerPage($event)"
			:footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      >

        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No hay
              <strong>Eventos</strong>
              disponibles para esta búsqueda
            </v-list-item-title>
          </v-list-item>
        </template>

        <!--Creation date-->
        <template #[`item.created_at`]="{item}">
          <span class="text-capitalize font-weight-medium text--primary">{{  `${item.created_at.slice(0,10)} - ${item.created_at.slice(11, 16)}` }}</span>
        </template>

        <template #[`item.name`]="{ item }">
          <span class="date-container">{{item.name}}</span>
        </template>

        <!-- Emails -->
        <template #[`item.email`]="{item}">
          <span class="date-container">{{item.email}}</span>
        </template>

        <!-- Associated Contact -->
        <template #[`item.contact`]="{item}">
          <span class="date-container">{{item.contact ? item.contact.name : ' - '}}</span>
        </template>

        <!--Event date-->
        <template #[`item.datetime`]="{item}">
          <span class="text-capitalize font-weight-medium text--primary">{{  `${item.datetime.slice(0,10)} - ${item.datetime.slice(11, 16)}` }}</span>
        </template>

        <!--Description-->
        <template #[`item.description`]="{item}">
          <div v-if="item.description" class="d-flex align-center">
            <div class="d-flex flex-column ms-3 text-justify">
              <span v-if="item.description.length <= 40">{{ item.description }}</span>
              <span class="d-flex" v-else>
                {{ item.description.substring(0, 35) + '...' }}
                <v-btn icon x-small @click="showCompleteDescription(item.description)">
                  <v-icon color="primary">{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </span>
            </div>
          </div>
          <div v-else class="d-flex align-center justify-center">
            <span > - </span>
          </div>
          
        </template>

        <!--Notification-->
        <template #[`item.notify`]="{item}">
          <v-icon v-if="item.notify" icon color="success">
            {{ icons.mdiCheckCircleOutline }}
          </v-icon>

          <v-icon v-else icon color="error">
            {{ icons.mdiCloseCircleOutline }}
          </v-icon>
        </template>

        <!--Remind minutes-->
        <template #[`item.remind_minutes`]="{item}">
          <span class="date-container">{{item.remind_minutes ? item.remind_minutes : ' - '}}</span>
        </template>


        <!--Status-->
        <template #[`item.status`]="{item}">
          <div class="status-div">
            <v-chip
              small
              :color="resolveUserStatusVariant(item.status.id)"
              :class="[`${resolveUserStatusVariant(item.status.id)}--text`]"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ item.status.label }}
            </v-chip>
          </div>
        </template>
      </v-data-table>
      <!-- Show complete description Text -->
      <template>
          <v-dialog v-model="description_modal" max-width="500">
            <v-card>
              <v-card-text>
                {{ description }}
              </v-card-text>
            </v-card>
          </v-dialog>
      </template>
    </v-card>
  </div>
</template>

<script>
import {ref} from '@vue/composition-api';
import {
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiPlus
} from '@mdi/js'

export default {
  data:() => {
    return {
      current_page: 1,
      page_size: 10,
      stateFilterValue: -1,
      description: '',
      description_modal: false
    }
  },
  methods: {
    async getEventsList() {
      this.loading = true
      try {
        const query = {
          company_id: this.getSelectedCompany,
          page_size: this.page_size,
          page: this.current_page,
          status: this.stateFilterValue,
        }
        const response = await this.$api.getEventsList(query)
        this.eventListTable = response.results
        this.totalEventListTable = response.count

      } catch (error) {
        console.log(error)
      } finally { this.loading = false }
    },

    changingEventPage(e) {
      this.current_page = e
      this.getEventsList()
    },

    changingEventItemsPerPage(e) {
      this.page_size = e
      this.getEventsList()
    },
    setFilterStatus() {
      this.current_page = 1
      this.getEventsList()
    },
    showCompleteDescription(description) {
      this.description = description
      this.description_modal = true
    },
  },
  mounted() {
    if(this.getSelectedCompany) {
      this.getEventsList()
    }
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
  },
  watch: {
    getSelectedCompany(newValue) {
      if(newValue) {
        this.current_page = 1
        this.page_size = 10
        this.getEventsList()
      }
    }
  },
  setup() {
    const totalEventListTable = ref(1);
    const eventListTable = ref([]);
    const loading = ref(false);

    const tableColumns = [
    { text: 'Fecha y hora de creación', value: 'created_at', align: 'center', sortable: false, width: '200px' },
    { text: 'Nombre del evento', value: 'name', align: 'center', sortable: false, width: '300px'},
    { text: 'Email invitado', value: 'email', align: 'center', sortable: false },
    { text: 'Contacto asociado', value: 'contact', align: 'center', sortable: false },
    { text: 'Fecha y hora del evento', value: 'datetime', align: 'center', sortable: false, width: '200px' },
    { text: 'Descripción', value: 'description', align: 'center', sortable: false },
    { text: 'Notificar sobre el evento', value: 'notify', align: 'center', sortable: false },
    { text: 'Minutos antes a notificar', value: 'remind_minutes', align: 'center', sortable: false },
    { text: 'Estado', value: 'status', align: 'center', sortable: false },
    ];

    const resolveUserStatusVariant = status => {
      if (status === 0) return 'error'
      if (status === 1) return 'success'
      if (status === 2) return 'primary'

      return 'primary'
    }

    return {
      loading,
      tableColumns,
      totalEventListTable,
      eventListTable,
      resolveUserStatusVariant,
      icons: {
        mdiCheckCircleOutline,
        mdiCloseCircleOutline,
        mdiPlus
      }
    }
  }
}
</script>