<template>

  <div>
    <v-dialog
      v-model="scheduleModal"
      width="400"
    >
      <!-- <v-card style="position: sticky; bottom:200;"> -->
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
         Programar envío
        </v-card-title>
        <!-- aclaration text -->
        <v-card-text class="mb-1">
          Si desea programar el envío seleccione fecha y horario, de lo contrario cierre esta ventana y presione "enviar"
        </v-card-text>
        
        <v-form ref="schedule_form" @submit.prevent="scheduleMessage">

          <!-- datepicker -->
         
          <v-col cols="8" class="pb-0" >
              <v-text-field
              label="Fecha"
              type="date"
              v-model="date"
              :rules="[v => !!v || 'Campo requerido']"
              :min="minDate"
              outlined
              dense
              required
              ></v-text-field>
          </v-col>
          
          <!-- timepicker -->
          <v-col
            cols="11"
            sm="5"
            class="pt-0"
          >
            <v-menu
              ref="menu"
              v-model="timeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="time"
                  label="Hora"
                  :prepend-icon="icons.mdiClockTimeFourOutline"
                  :rules="[v => !!v || 'Campo requerido']"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="timeMenu"
                v-model="time"
                full-width
                format="24hr"
              >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(time)"
              >
                  OK
              </v-btn>
              </v-time-picker>
            </v-menu>
          </v-col>

          <!-- send - cancel -->
          <v-card-actions>
            <span 
            class="error--text error-msg"
            v-if="error_msg"
            >{{ error_msg }}</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="scheduleModal = false">
                CANCELAR
            </v-btn>
            <v-btn
              color="primary"
              @click="scheduleModal = false"
              type="submit"
              :disabled="buttonDisabled"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        
        </v-form>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import { mdiCalendarMonth, mdiClockTimeFourOutline } from '@mdi/js';

export default {

  data: () => {
    return { 
        scheduleModal: false,
        minDate: null,
        date: null,
        time: null,
        timeMenu: false,
        error_msg: null,
    }
  },
  methods: {
    setMinDate(){
        const date = new Date();
        this.minDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
                .toISOString()
                .split("T")[0];
    },
    async scheduleMessage(){
      this.$emit('schedule-message-order', { send_date_program: this.date, send_time_program: this.time });
    },
    reset() {
      this.date = null
      this.time = null
    }
  },
  computed: {
    buttonDisabled() {
      return !this.date || !this.time;
    }
  },
  setup() {
    return {
      icons: {
        mdiCalendarMonth,
        mdiClockTimeFourOutline
      }
    }
  },

}
</script>

<style scoped>

</style>