import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"width":"400"},model:{value:(_vm.scheduleModal),callback:function ($$v) {_vm.scheduleModal=$$v},expression:"scheduleModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Programar envío ")]),_c(VCardText,{staticClass:"mb-1"},[_vm._v(" Si desea programar el envío seleccione fecha y horario, de lo contrario cierre esta ventana y presione \"enviar\" ")]),_c(VForm,{ref:"schedule_form",on:{"submit":function($event){$event.preventDefault();return _vm.scheduleMessage.apply(null, arguments)}}},[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"8"}},[_c(VTextField,{attrs:{"label":"Fecha","type":"date","rules":[function (v) { return !!v || 'Campo requerido'; }],"min":_vm.minDate,"outlined":"","dense":"","required":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"11","sm":"5"}},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Hora","prepend-icon":_vm.icons.mdiClockTimeFourOutline,"rules":[function (v) { return !!v || 'Campo requerido'; }],"readonly":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timeMenu),callback:function ($$v) {_vm.timeMenu=$$v},expression:"timeMenu"}},[(_vm.timeMenu)?_c(VTimePicker,{attrs:{"full-width":"","format":"24hr"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.time)}}},[_vm._v(" OK ")])],1):_vm._e()],1)],1),_c(VCardActions,[(_vm.error_msg)?_c('span',{staticClass:"error--text error-msg"},[_vm._v(_vm._s(_vm.error_msg))]):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.scheduleModal = false}}},[_vm._v(" CANCELAR ")]),_c(VBtn,{attrs:{"color":"primary","type":"submit","disabled":_vm.buttonDisabled},on:{"click":function($event){_vm.scheduleModal = false}}},[_vm._v(" Confirmar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }