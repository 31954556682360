import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,[_vm._v(" Eventos ")]),_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VCol,{attrs:{"cols":"2","md":"2"}},[_c(VSelect,{staticClass:"mb-4",attrs:{"items":[{name: '-', value: -1}, {name: 'Pendiente', value: 0}, {name: 'Creado', value: 1}, {name: 'Fallido', value: 2}],"hide-details":"true","label":"Estado","item-text":"name","item-value":"value","outlined":"","dense":""},on:{"change":_vm.setFilterStatus},model:{value:(_vm.stateFilterValue),callback:function ($$v) {_vm.stateFilterValue=$$v},expression:"stateFilterValue"}})],1),_c(VSpacer)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"page":_vm.current_page,"items":_vm.eventListTable,"server-items-length":_vm.totalEventListTable,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 15] }},on:{"update:page":function($event){return _vm.changingEventPage($event)},"update:items-per-page":function($event){return _vm.changingEventItemsPerPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No hay "),_c('strong',[_vm._v("Eventos")]),_vm._v(" disponibles para esta búsqueda ")])],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-medium text--primary"},[_vm._v(_vm._s(((item.created_at.slice(0,10)) + " - " + (item.created_at.slice(11, 16)))))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"date-container"},[_vm._v(_vm._s(item.name))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"date-container"},[_vm._v(_vm._s(item.email))])]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"date-container"},[_vm._v(_vm._s(item.contact ? item.contact.name : ' - '))])]}},{key:"item.datetime",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-medium text--primary"},[_vm._v(_vm._s(((item.datetime.slice(0,10)) + " - " + (item.datetime.slice(11, 16)))))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.description)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3 text-justify"},[(item.description.length <= 40)?_c('span',[_vm._v(_vm._s(item.description))]):_c('span',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(item.description.substring(0, 35) + '...')+" "),_c(VBtn,{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.showCompleteDescription(item.description)}}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.icons.mdiPlus))])],1)],1)])]):_c('div',{staticClass:"d-flex align-center justify-center"},[_c('span',[_vm._v(" - ")])])]}},{key:"item.notify",fn:function(ref){
var item = ref.item;
return [(item.notify)?_c(VIcon,{attrs:{"icon":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircleOutline)+" ")]):_c(VIcon,{attrs:{"icon":"","color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCloseCircleOutline)+" ")])]}},{key:"item.remind_minutes",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"date-container"},[_vm._v(_vm._s(item.remind_minutes ? item.remind_minutes : ' - '))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"status-div"},[_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:[((_vm.resolveUserStatusVariant(item.status.id)) + "--text")],attrs:{"small":"","color":_vm.resolveUserStatusVariant(item.status.id)}},[_vm._v(" "+_vm._s(item.status.label)+" ")])],1)]}}],null,true)}),[_c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.description_modal),callback:function ($$v) {_vm.description_modal=$$v},expression:"description_modal"}},[_c(VCard,[_c(VCardText,[_vm._v(" "+_vm._s(_vm.description)+" ")])],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }