<template>
    <div style="width: fit-content; margin-left: auto;">
      <app-card v-if="getUserRole !== 0">
        <app-card-body class="mb-5">
          <v-row justify="space-between" >
            <app-card-header class="pa-0">Créditos</app-card-header>
            <app-card-header class="pr-0 pl-2 py-0" align="end"> 
              <v-icon size="20">{{ icons.mdiAlphaCCircle }}</v-icon>
              {{ getCredits }}
            </app-card-header>         
          </v-row>
        </app-card-body>
      </app-card>

    </div>
</template>


<script>
import AppCard from '@/@core/components/app-card/AppCard'
import AppCardBody  from '@/@core/components/app-card/AppCardBody'
import AppCardHeader  from '@/@core/components/app-card/AppCardHeader'
import { mdiAlphaCCircle } from '@mdi/js';

export default {
  components: {
    AppCard,
    AppCardBody,
    AppCardHeader
  },
  mounted() {
    if (this.getUserRole !== 0 && this.getSelectedCompany) {
      this.getCreditsLocal()
    }
  },
  methods: {
    async getCreditsLocal() {
      const credits = await this.$api.getCurrentUserCredits(this.getSelectedCompany) 
      if (credits) {
        this.$store.dispatch('session/setCredits', credits)    
      } else {
        this.$store.dispatch('session/setCredits', 0)    
      }
    }
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
    getCredits() { return this.$store.getters['session/getCredits']() },

  },
  watch: {
    getSelectedCompany (newValue) {
      if (newValue) {
        this.getCreditsLocal()
      }
    },
    getUserRole (newValue) {
      if (newValue) {
        this.getCreditsLocal()
      }
    }
  },
  setup() {
    return {
      // icons
      icons: {
        mdiAlphaCCircle,
      },
    }
  },

}

</script>