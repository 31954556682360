
<template>
  <v-form ref="messageForm" @submit.prevent="sendMessage">
    <v-row>
      <v-col cols="4" md="4" class="pr-0 pt-3">
        <v-select
          id="selectorflags"
          name="selectorflags"
          v-model="items[0]"
          :items="items"
          label="Cod. Area"
          :rules="[v => !!v || 'Caracteristica requerida']"
          required
          hide-details="auto"
          outlined
          dense
          @change="changePrefix"
        ></v-select>
      </v-col>

      <v-col cols="8" md="8" class="pl-0" align="right">
        <v-text-field
            @keyup="checkNumber()"
            id="mobileHorizontalIcons"
            ref="phone_ref"
            class="input-phone"
            v-model="phone"
            type="number"
            outlined
            :rules="[v => !!v || 'El número es necesario']"
            :append-icon="show_ok? icons.mdiCheckCircleOutline : null"
            :prepend-inner-icon="icons.mdiCellphone"
            dense
            placeholder="Número de Whatsapp"
            hide-details
            label="Whatsapp"
          ></v-text-field>
        <span class="caption">Ejemplo: 1143234556</span>
      </v-col>

      <v-col align="right" cols="12" v-if="no_wpp && this.phone.length > 7">
        <span class="caption" style="color:red;">
          {{ other_wpp_error
            ? error_msg
              ? error_msg
              : 'La validacion no pudo realizarse, por favor vuelva a intentarlo'
            : '¡Este telefono no tiene Whatsapp!'
          }}
        </span>
      </v-col>

      <v-col cols="12" md="12">
        <v-text-field
          ref="client_name_ref"
          id="firstnameHorizontalIcons"
          v-model.trim="client_name"
          :prepend-inner-icon="icons.mdiAccountOutline"
          :rules="[v => !!v || 'Campo requerido']"
          :error="error_client_name"
          @keyup="error_client_name = false"
          outlined
          dense
          autocomplete="off"
          hide-details
          label="Cliente"

        ></v-text-field>
      </v-col>

      <!-- <v-col cols="12" md="12" >
        <label for="emailHorizontalIcons">Mensaje</label>
      </v-col> -->

      <v-col cols="12" md="12" >
        <v-textarea
          ref="message_ref"
          :prepend-inner-icon="icons.mdiMessageBulleted"
          id="textarea-input"
          v-model.trim="message"
          :rules="[v => !!v || 'Escriba algún mensaje']"
          :error="error_message_body"
          @keyup="error_message_body = false"
          counter="1000"
          maxlength="1000"
          placeholder="Mensaje"
          outlined
          dense
          hint=""
          label="Mensaje"
          style="margin-bottom:-7px"
        ></v-textarea>
        <EmojiPicker style="margin-bottom:7px" @insert-emoji="insertToMessage"/>
      </v-col>

      <v-checkbox
          ref="metadata_ref"
          class="shrink col-12 mr-2 mt-0 mb-3"
          label="Agregar informacion de contacto"
          v-model="metadata"
          @click="checkIfAccessToken()"
          hide-details="auto"
      ></v-checkbox>

      <v-col v-if="error_access_token">
        <span style="color: #ff4c51">{{error_access_token}}</span>
      </v-col>

      <v-col
      cols="12"
      class="pt-0"
      v-if="metadata && !error_access_token"
      >
        <v-text-field
          ref="dni_ref"
          type="number"
          v-model.trim="dni"
          :disabled="!metadata"
          :prepend-inner-icon="icons.mdiCardAccountDetailsOutline"
          :error="error_metadata"
          @keyup="error_metadata = false"
          outlined
          dense
          hide-details
          class="mb-3 col-6"
          label="DNI"
        ></v-text-field>

        <v-text-field
          ref="link1_ref"
          v-model.trim="link1.link"
          :disabled="!metadata"
          :prepend-inner-icon="icons.mdiAccountCheck"
          :error="error_metadata"
          @keyup="error_metadata = false"
          outlined
          dense
          hide-details
          class="mb-3 col-9"
          label="Link 1"
        ></v-text-field>

        <v-text-field
          ref="link2_ref"
          v-model.trim="link2.link"
          :disabled="!metadata"
          :prepend-inner-icon="icons.mdiAccountCheck"
          :error="error_metadata"
          @keyup="error_metadata = false"
          outlined
          dense
          hide-details
          class="mb-3 col-9"
          label="Link 2"
        ></v-text-field>

        <v-text-field
          ref="email_ref"
          v-model.trim="email"
          :disabled="!metadata"
          :prepend-inner-icon="icons.mdiAccountCheck"
          :error="error_metadata"
          @keyup="error_metadata = false"
          outlined
          dense
          hide-details
          class="mb-3 col-9"
          label="Email"
        ></v-text-field>

        <v-text-field
          ref="dato1_ref"
          v-model.trim="dato1.dato"
          :disabled="!metadata"
          :prepend-inner-icon="icons.mdiAccountCheck"
          :error="error_metadata"
          @keyup="error_metadata = false"
          outlined
          dense
          hide-details
          class="mb-3 col-9"
          label="Dato 1"
        ></v-text-field>

      <v-list class="pa-0" dense >
        <v-list-item
        class="pa-0 col-12"
        v-for="(input, k) in custom_fields" :key="k"
        >
          <v-text-field
          ref="metadata_ref"
          v-model.trim="custom_fields[k].dato"
          :disabled="!metadata"
          :prepend-inner-icon="icons.mdiAccountCheck"
          :rules="[v => !!v || 'Campo requerido']"
          outlined
          dense
          hide-details
          class="mb-3 col-9"
          :label="`Dato ${k+2}`"
          ></v-text-field>
          <v-list-item-icon >
            <v-icon
            class="custom-icon"
            @click="removeCustom(k)"
            >
              $delete
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <v-col >
        <v-btn
        class="custom-btn"
        icon @click="addCustom(custom_fields)"
        :disabled="custom_fields.length>=9"
        >
          <v-icon color="primary" v-text="icons.mdiPlusCircleOutline"></v-icon>
        </v-btn>
          <span v-if="custom_fields.length<9">Agregue sus campos personalizados</span>
          <span style="color: #ff4c51" v-else> Ha llegado al limite de campos personalizados  </span>
      </v-col>

      </v-col>

      <v-col align="right" cols="12" v-if="getUserRole !== 0 && getCredits <= 0">
        <span class="caption no-credits">¡No tienes créditos en esta empresa para mandar mensajes!</span>
      </v-col>

    </v-row>
    <!-- <v-col align="right" cols="3" style="display: none">
      <v-btn type="submit" color="primary">
        Enviar
      </v-btn>
    </v-col> -->
  </v-form>
</template>

<script>
const debounce = require('debounce');
// eslint-disable-next-line object-curly-newline
import EmojiPicker from '@/components/Campaigns/EmojiPicker';
import {
  mdiAccountOutline,
  mdiCellphone,
  mdiLockOutline,
  mdiMessageBulleted,
  mdiCheckCircleOutline,
  mdiEmailOutline,
  mdiCardAccountDetailsOutline,
  mdiAccountCheck,
  mdiPlusCircleOutline
} from '@mdi/js'

export default {
  data: () => {
    return {
      show_ok: false,
      other_wpp_error: false,
      error_msg: null,
      error_access_token: null,

      client_name: '',
      message: '',
      phone: null,
      metadata: false,
      dni: '',
      email: '',
      dato1: {},
      link1: {link: ''},
      link2: {link: ''},
      custom_fields: [],

      error_metadata: false,
      error_customFields:false,
      error_client_name: false,
      error_message_body: false,

    }
  },
  components: {
    EmojiPicker,
  },
  // mounted() {
  //   if (this.getSelectedCompany) {
  //     console.log(this.getSelectedCompany)
  //   }
  // },
  methods: {
    checkNumber: debounce(function (e) {
      if (this.phone && this.phone.length > 7) {
        const data = {
          company_id: this.$store.getters['session/getSelectedCompany'](),
          phone: this.phone,
          prefix: this.prefix,
        }

        this.$api.checkWhatsNumber(data)
          .then(response => {
            if (response[0] === 'invalid') this.no_wpp = true
            if (response[0] === 'valid') this.no_wpp = false
            this.other_wpp_error = false
          })
          .catch(e => {
            this.no_wpp = true
            if (e?.response?.status === 403) {
              this.error_msg = e?.response?.data?.message
            } else {
              this.error_msg = null
            }
            this.other_wpp_error = true
          })
          .finally(() => {
            this.show_ok = !this.no_wpp
          })

      }
    }, 1000),

    changePrefix(e) {
      const matches = e.match(/\d+/g);

      if (matches) this.prefix = Number(matches[0])
    },

    validateBlanks() {
      let valid = true

      if (!this.client_name.length) {
        this.error_client_name = true
        valid = false
      }

      if (!this.message.length) {
        this.error_message_body = true
        valid = false
      }

      // if (this.metadata && this.custom_fields.length && !this.custom_fields[0].dato) {
      //   this.error_customFields = true
      //   valid = false
      // }

      return valid
    },

    async checkIfAccessToken() {
      try {
        const response = await this.$api.checkIfAccessToken(this.getSelectedCompany)
        this.error_access_token = null

      } catch (e) {

        if (e?.response?.status === 403) {
          this.error_access_token = e?.response?.data?.message
          this.metadata = false
        }

      }
    },

    addCustom() {
      if(this.custom_fields.length<9){
      this.custom_fields.push({})
      console.trace()
    }
    },

    removeCustom(k) {
      this.custom_fields.splice(k, 1)
    },

    generateMsgForm() {
      let form = {
            company_id: this.$store.getters['session/getSelectedCompany'](),
            phone: this.phone,
            prefix: this.prefix,
            client: this.client_name,
            message: this.message,
            metadata: this.metadata
          }

          if (this.metadata) {
            let custom = this.custom_fields
            if(this.dato1.dato) {
              custom.unshift(this.dato1)
            }
            if(this.email) {
              form.email = this.email
            }
            if(this.dni) {
              form.dni = this.dni
            }
            form.customs = custom
            if(this.link1.link) {
              form.customs.push(this.link1)
            }
            if(this.link2.link) {
              form.customs.push(this.link2)
            }
            form.customs = JSON.stringify(custom)
          }
          console.log(form)
      return form
    },

    sendMessage() {

      if (this.$refs.messageForm.validate() && this.validateBlanks()) {
        if (this.getCredits <= 0 && this.getUserRole !== 0) {
          // console.log("no credits");
          return
        } else {
          //refactorizé acá el form creandolo ahora desde computed
          //para poder accederlo también desde otros componentes de Messages.vue
          const form = this.generateMsgForm()

          // console.log(form)
          this.$emit('send-message-order', form)
          this.custom_fields = []

        }

      } else {
        console.log('error VALIDACION')
      }
    },
    reset() {
      this.$refs.client_name_ref.reset()
      this.$refs.message_ref.reset()
      this.$refs.phone_ref.reset()
      this.show_ok = false
      this.no_wpp = false

      if (this.metadata) {
        this.$refs.dni_ref.reset()
        this.$refs.email_ref.reset()
        this.$refs.dato1_ref.reset()
        this.$refs.link1_ref.reset()
        this.$refs.link2_ref.reset()
        this.custom_fields = []
      }

    },
    insertToMessage(item) {
      const textarea = document.getElementById('textarea-input')
      if(textarea.selectionStart || textarea.selectionStart == '0') {
          let startPos = textarea.selectionStart
          let endPos = textarea.selectionEnd

          textarea.value = textarea.value.substring(0, startPos)
          + `${item}`
          + textarea.value.substring(endPos, textarea.value.length)
          textarea.selectionStart = startPos + item.length
          textarea.selectionEnd = startPos + item.length
      }
      this.message = textarea.value
    },
  },
  computed: {
    isValidToSend() {
      if (!this.metadata){
        return Boolean(
          (!this.no_wpp && this.phone) &&
          (this.client_name && this.client_name.length) &&
          (this.message && this.message.length)
          )
      } else {
          return Boolean(
          (!this.no_wpp && this.phone) &&
          (this.client_name && this.client_name.length) &&
          (this.message && this.message.length) &&
          ((this.dni && this.dni.length) || (this.dato1.dato) || (this.email && this.email.length) || (this.link1.link) || (this.link2.link)) 
          )
      }
    },
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    getCredits() { return this.$store.getters['session/getCredits']() },
  },
  watch: {
    isValidToSend(newValue) {
      this.$emit("is-valid-to-send", newValue)
    }
  },
  setup() {
    // const client_name = ref('')
    // const message = ref('')
    // const phone = ref()

    return {
      // client_name,
      // message,
      // phone,
      no_wpp: false,
      items: [
        "🇦🇷 (+549)",
        "🇧🇷 (+55)",
        "🇧🇴 (+591)",
        "🇨🇱 (+56)",
        "🇨🇴 (+57)",
        "🇪🇨 (+593)",
        "🇲🇽 (+52)",
        "🇵🇪 (+51) ",
        "🇵🇾 (+595)",
        "🇺🇾 (+598)",
        "🇻🇪 (+58)",
      ],
      prefix: 549,
      // icons
      icons: {
        mdiAccountOutline,
        mdiCellphone,
        mdiLockOutline,
        mdiMessageBulleted,
        mdiCheckCircleOutline,
        mdiEmailOutline,
        mdiCardAccountDetailsOutline,
        mdiAccountCheck,
        mdiPlusCircleOutline
      },
      dialog: false,
    }
  },
}
</script>
<style scoped>
  .no-credits {
    color:red;
    position: absolute;
    bottom: 4rem;
    right: 1.6rem;
  }
  .custom-icon:hover {
  color: red;
}
</style>
<style>
  .input-phone .v-input__append-inner .v-input__icon.v-input__icon--append .v-icon {
    color: #56ca00 !important;
  }
</style>
